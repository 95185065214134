.container {
  background: #eee;
  position: relative;
  cursor: text;
  display: block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.input {
  max-height: calc(100vh - 240px);
  overflow: auto;
  resize: none;
  word-wrap: normal;
  white-space: pre-wrap;
  padding: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.paragraph {
  font-family: monospace;
}

.placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 10px;
  display: inline-block;
  user-select: none;
  pointer-events: none;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.toolbar {
}

.toolbar button.toolbar_item {
}

.style_underline {
  text-decoration: underline;
}

.markup_node {
  color: blue;
}

.markup_node:hover {
  text-decoration: underline;
}

:has(+ span.pseudo_br):after {
  content: '\23ce';
}

span.pseudo_br {
  content: '';
  display: block;
  clear: inline-start;
}
